import React, { useState, useEffect } from "react";
import { Box, Toolbar, IconButton, Typography, Button, Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { signOut } from "firebase/auth";
import { auth } from "../../shared/firebase";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import HouseIcon from "@mui/icons-material/House";
import logo from "./logo.svg";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const Header = ({ user, userData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    if (user && userData) {
      setIsLoading(false);
    }
  }, [user, userData]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <>
      <Box className="site-header" sx={styles.header}>
        <Link to="/">
          <img src={logo} alt="logo" className="site-header__logo" />
        </Link>
        {user ? (
          isMobile ? (
            <MobileMenu {...{ isLoading, user, userData, handleLogout, navigate }} />
          ) : (
            <DesktopMenu {...{ isLoading, user, userData, handleLogout, navigate }} />
          )
        ) : (
          <LoginBanner isMobile={isMobile} navigate={navigate} />
        )}
      </Box>
      <hr className="site-header__divider" />
    </>
  );
};

const LoginBanner = ({ isMobile, navigate }) => (
  <Paper elevation={3} sx={styles.loginBanner}>
    <Button
      variant="contained"
      color="primary"
      startIcon={<LoginIcon />}
      onClick={() => navigate("/login")}
      sx={{ ...styles.loginButton, mr: 2 }}
    >
      Log In
    </Button>
    <Button
      variant="contained"
      color="primary"
      startIcon={<PersonAddIcon />}
      onClick={() => navigate("/register")}
      sx={styles.loginButton}
    >
      Sign Up
    </Button>
  </Paper>
);

const DesktopMenu = ({ handleLogout, navigate }) => (
  <Box sx={styles.desktopMenu}>
    <Toolbar sx={styles.toolbar}>
      <DashboardButton navigate={navigate} />
      <ProfileButton navigate={navigate} />
      <LogoutButton handleLogout={handleLogout} />
    </Toolbar>
  </Box>
);

const MobileMenu = ({ handleLogout, navigate }) => (
  <Box sx={styles.mobileMenu}>
    <Toolbar sx={styles.toolbar}>
      <DashboardButton mobile navigate={navigate} />
      <Box sx={{ flexGrow: 1 }} />
      <ProfileButton mobile navigate={navigate} />
      <LogoutButton handleLogout={handleLogout} mobile />
    </Toolbar>
  </Box>
);

const DashboardButton = ({ mobile, navigate }) => (
  <Button
    variant="secondary"
    onClick={() => navigate("/dashboard")}
    startIcon={<HouseIcon sx={{ fontSize: 16 }} />}
    sx={mobile ? styles.mobileButton : styles.desktopButton}
  >
    Dashboard
  </Button>
);

const ProfileButton = ({ mobile, navigate }) => (
  <IconButton
    color="inherit"
    onClick={() => navigate("/profile")}
    sx={mobile ? styles.mobileIconButton : styles.desktopIconButton}
    title="Settings"
  >
    <SettingsIcon sx={{ fontSize: 20 }} />
    <Typography variant="caption" sx={styles.buttonText}>
      Profile
    </Typography>
  </IconButton>
);

const LogoutButton = ({ handleLogout, mobile }) => (
  <IconButton
    color="inherit"
    onClick={handleLogout}
    sx={mobile ? styles.mobileLogoutButton : styles.desktopLogoutButton}
    title="Log out"
  >
    <LogoutIcon sx={{ fontSize: 20 }} />
  </IconButton>
);

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 1250,
    margin: '0 auto'
  },
  desktopMenu: {
    mt: 8,
    position: 'relative',
    right: -25,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mobileMenu: {
    mb: 6,
  },
  toolbar: {
    minHeight: "48px",
    p: 1,
    marginLeft: 'auto'
  },
  desktopButton: {
    mr: 2,
    fontSize: "0.75rem",
    py: 0.25,
    px: 1
  },
  mobileButton: {
    mr: 1,
    fontSize: "0.75rem",
    py: 0.25,
    px: 1
  },
  desktopWelcome: {
    mr: 2,
  },
  mobileWelcome: {
    mr: 1.5,
    display: {
      xs: 'none',
      sm: 'block',
    }
  },
  desktopIconButton: {
    mr: 1,
    p: "4px"
  },
  mobileIconButton: {
    mr: 0.5,
    p: "4px"
  },
  buttonText: {
    mr: 1,
    ml: 0.5,
  },
  desktopLogoutButton: {
    p: "4px",
    ml: 1
  },
  mobileLogoutButton: {
    p: "4px"
  },
  loginBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
    zIndex: 2,
    background: "none",
    boxShadow: 'none',
    padding: '8px',
  },
  loginText: {
    marginRight: 2,
  },
  loginButton: {
    whiteSpace: 'nowrap',
    border: "1px solid #555",
    "@media (max-width: 420px)": {
      padding: "4px 8px",
      fontSize: "0.75rem",
    }
  },
};

export default Header;
